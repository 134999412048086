import { Box } from "@mui/material";
import cn from "classnames";

import { observer } from "mobx-react";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import _get from "lodash/get";

import {
    WatchersStatusType,
    WatchingType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import MobilePot from "src/components/controls/bank/coinAnimation/MobilePotContainer/mobilePot";
import CoinAnimationContainer from "src/components/controls/bank/coinAnimation/coinAnimationContainer";

import useChangeTheme from "src/hooks/useChangeTheme";
import useCheckFullScreen from "src/hooks/useCheckFullScreen";
import useOrientation from "src/hooks/useOrientation";
import useStores from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";

import ActionAndReturn from "src/pages/game-new2/components/actionAndReturn";
import useDoublingStageStatus from "src/pages/game-new/hooks/useDoublingStageStatus";
import { usePlayer } from "src/sounds/use-player";
import {
    ChessboardType,
    GameModeTypeNew,
    UserBalanceType,
} from "src/store/models";

import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";

import { useStyles } from "src/pages/game-new2/layouts/layoutTypes/gameChessboardWrapper/styles";
import CoinRiseComponent from "src/components/controls/bank/coinAnimation/coinRiseComponent";

const RiseAndCoinInitiator = () => {
    const { gameStore, authStore } = useStores();
    const { isMultiTable } = useChessBoardProps();
    const location = useLocation();
    const windowSize = useWindowSize();
    const { showOpponentAcceptedDoublingDialog } = useDoublingStageStatus();
    const orientation = useOrientation();
    const isGamePage = useRouteMatch("/game/:id");

    //MOBX STORE DATA
    const amountBank = gameStore?.currentGameNew?.bank;
    const balanceType = gameStore?.currentGameNew?.gameType;
    const curChessMove = gameStore.currentGameNew?.curChessMove;

    const curMove = gameStore.currentGameNew?.curMove;
    const myMove = gameStore.isMyMove;
    const winner = gameStore?.currentGameNew?.winner;
    const doubleThinking = gameStore.dialogs.oponentThinkingDialog.isOpen;
    const extra = gameStore?.currentGameNew?.extra;
    const me = authStore.currentUser?._id;
    const waitingBetApply = extra
        ? extra["waitingBetApply"] && curMove !== authStore.currentUser?._id
        : false;

    const myActiveProposal = me === curChessMove ? true : false;

    const isWon = winner === authStore.currentUser?._id ? true : false;
    const isRaiseActive = extra && extra["waitingBetApply"] !== 0;
    const isLeftBet = !!extra && extra.leftBet > 0;
    const isRightBet = !!extra && extra.rightBet > 0;

    const viewMode = gameStore.isViewMode();

    //MEMO || CONDITIONS
    const myColor = useMemo(() => {
        return gameStore.getMyColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
    ]);

    const opponentColor = useMemo(() => {
        return gameStore.getOpponentColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
    ]);

    //USE STATE
    const [elemHeight, setHeight] = useState<number | undefined>();
    const [initAnimateChipsLeft, iniTsetChipsAnimateLeft] = useState<{
        [key: string]: number | boolean;
    }>({
        vertical: false,
        horizontal: false,
    });
    const [initAnimateChipsRight, iniTsetChipsAnimateRight] = useState<{
        [key: string]: number | boolean;
    }>({
        vertical: false,
        horizontal: false,
    });

    const [respAnimateChipsLeft, respSetChipsAnimateLeft] = useState<{
        [key: string]: number | boolean;
    }>({
        vertical: false,
        horizontal: false,
    });
    const [respAnimateChipsRight, respSetChipsAnimateRight] = useState<{
        [key: string]: number | boolean;
    }>({
        vertical: false,
        horizontal: false,
    });

    const [runRiseCounter, setAnimationRiseCounter] = useState<boolean>(false);
    const [memoAmount, setMemoAmount] = useState<number>(0);
    const [memoBet, setMemoBet] = useState<number | null>(0);
    const [memoRise, setMemoRise] = useState<number | null>(0);

    const animateRiseCoin = (
        startCordselement,
        element,
        status: boolean,
        setFn
    ) => {
        if (status) {
            return setFn({
                vertical: 0,
                horizontal: 0,
            });
        }

        if (!status && element && startCordselement) {
            const riseCoinPosition = startCordselement.getBoundingClientRect();
            const topPlayerElementCoords = element.getBoundingClientRect();
            const subtractionVertival = (
                topPlayerElementCoords?.top - riseCoinPosition.top
            ).toFixed(2);
            const subtractionHorizontal = (
                topPlayerElementCoords?.left - riseCoinPosition.left
            ).toFixed(2);

            return setFn({
                vertical: +subtractionVertival,
                horizontal: +subtractionHorizontal,
            });
        }
    };

    const vertical = !myActiveProposal
        ? initAnimateChipsLeft?.vertical
        : respAnimateChipsLeft?.vertical;
    const horizontal = !myActiveProposal
        ? initAnimateChipsLeft?.horizontal
        : respAnimateChipsLeft.horizontal;
    const vertical2 = !myActiveProposal
        ? initAnimateChipsRight?.vertical
        : respAnimateChipsRight?.vertical;
    const horizontal2 = !myActiveProposal
        ? initAnimateChipsRight?.horizontal
        : respAnimateChipsRight?.horizontal;

    const classes = useStyles({
        elemHeight,
        vertical,
        horizontal,
        vertical2,
        horizontal2,
    });

    const initRiseCoinPosition = useMemo(() => {
        return document.getElementById(
            `initRiseCoinPosition-${gameStore?.currentGameNew?._id}`
        );
    }, [gameStore?.currentGameNew?._id, memoBet, windowSize]);

    const initAcceptRiseCoinPosition = useMemo(() => {
        return document.getElementById(
            `initAcceptRiseCoinPosition-${gameStore?.currentGameNew?._id}`
        );
    }, [gameStore?.currentGameNew?._id, memoBet, windowSize]);

    const opponentElement = useMemo(() => {
        return document.getElementById(
            `${opponentColor}-${gameStore?.currentGameNew?._id}`
        );
    }, [gameStore?.currentGameNew?._id, memoBet, opponentColor, windowSize]);

    const meElement = useMemo(() => {
        return document.getElementById(
            `${myColor}-${gameStore?.currentGameNew?._id}`
        );
    }, [gameStore?.currentGameNew?._id, memoBet, myColor, windowSize]);

    useEffect(() => {
        const waitingBetApply = extra && extra["waitingBetApply"];
        if (waitingBetApply && amountBank) {
            if (waitingBetApply > 0) {
                setMemoAmount(amountBank);
                setMemoRise(waitingBetApply);
                return;
            }
            if (waitingBetApply === 0) {
                if (!memoRise) {
                    return setMemoRise(waitingBetApply);
                }
                if (memoRise) {
                    let riseMemoDelay;
                    riseMemoDelay = setTimeout(() => {
                        setMemoRise(null);
                        setAnimationRiseCounter(false);
                    }, 3000);
                    return () => clearTimeout(riseMemoDelay);
                }
            }
        }
    }, [extra, memoRise, amountBank, waitingBetApply]);

    useEffect(() => {
        if (!memoBet && amountBank) return setMemoBet(amountBank);
    }, [memoBet, amountBank]);

    const [coordsMemo, setCoords] = useState<boolean>(false);

    useEffect(() => {
        if (winner && isLeftBet && !isRightBet) {
        }
        if (winner) return;

        if (opponentElement && initRiseCoinPosition && !coordsMemo) {
            setCoords(true);
            animateRiseCoin(
                initRiseCoinPosition,
                opponentElement,
                false,
                iniTsetChipsAnimateLeft
            );
            animateRiseCoin(
                initAcceptRiseCoinPosition,
                opponentElement,
                false,
                respSetChipsAnimateRight
            );
        }
        if (meElement && initAcceptRiseCoinPosition && !coordsMemo) {
            setCoords(true);
            animateRiseCoin(
                initAcceptRiseCoinPosition,
                meElement,
                false,
                iniTsetChipsAnimateRight
            );
            animateRiseCoin(
                initRiseCoinPosition,
                meElement,
                false,
                respSetChipsAnimateLeft
            );
        }
    }, [
        meElement,
        opponentElement,
        winner,
        initRiseCoinPosition,
        initAcceptRiseCoinPosition,
        myActiveProposal,
        coordsMemo,
    ]);

    //@ts-ignore

    useLayoutEffect(() => {
        let activeMemoBank;
        if (isRightBet && amountBank) {
            activeMemoBank = setTimeout(() => {
                setMemoBet(amountBank);
            }, 2000);
            return;
        }
        clearTimeout(activeMemoBank);
    }, [isRightBet, amountBank]);

    useLayoutEffect(() => {
        let activeRiseDelay;
        if (!isLeftBet && !isLeftBet) {
            setMemoRise(null);
            setMemoAmount(0);
        }
        if (!runRiseCounter && isLeftBet && isRightBet) {
            activeRiseDelay = setTimeout(() => {
                setAnimationRiseCounter(true);
            }, 300);
            return () => {
                clearTimeout(activeRiseDelay);
            };
        }
        clearTimeout(activeRiseDelay);
    }, [isLeftBet, isRightBet, runRiseCounter]);

    useEffect(() => {
        if (
            isLeftBet &&
            isRightBet &&
            !winner &&
            runRiseCounter &&
            amountBank
        ) {
            let interval;
            if (
                typeof memoBet === "number" &&
                !!memoRise &&
                typeof memoRise === "number"
            ) {
                if (memoBet >= memoAmount + memoRise * 2) {
                    clearInterval(interval);
                    setMemoBet(amountBank);
                    setAnimationRiseCounter(false);
                    setMemoRise(null);
                    setMemoAmount(0);
                }
                interval = setInterval(() => {
                    const result = memoBet + (memoRise * 2) / 8;
                    if (result >= memoBet) setMemoBet(result);
                }, 35);
                return () => clearInterval(interval);
            }
        }
    }, [
        memoBet,
        memoRise,
        amountBank,
        memoAmount,
        isLeftBet,
        isRightBet,
        runRiseCounter,
    ]);

    return (
        <>
            <Box
                id={`initRiseCoinPosition-${gameStore?.currentGameNew?._id}`}
                className={cn(classes.initRiseCoinPosition, {
                    active: isLeftBet,
                    isWinnerWithRejectFly: winner && isLeftBet && !isRightBet,
                    moveAnimation: isLeftBet && isRightBet && !isWon,
                    hidePropose: isLeftBet && isRightBet && winner,
                })}
            >
                <CoinRiseComponent
                    isGamePage={!!isGamePage}
                    chessboardType={ChessboardType.mobileGame}
                    balanceType={UserBalanceType.play}
                    amountProp={memoRise || 0}
                />
            </Box>
            <Box
                className={cn(classes.middleCoinBox, {
                    active: !doubleThinking,
                })}
            >
                <MobilePot
                    isGamePage={!!isGamePage}
                    chessboardType={ChessboardType.mobileGame}
                    resultData={winner || (isLeftBet && isRightBet)}
                    balanceType={UserBalanceType.coins}
                    amountProp={memoBet || amountBank}
                    classes={
                        !doubleThinking && isRaiseActive
                            ? "runRiseAnimation"
                            : null
                    }
                >
                    <CoinAnimationContainer
                        balanceType={balanceType}
                        isMultiTable={isMultiTable}
                        chessboardType={ChessboardType.mobileGame}
                        memoRise={!!memoRise}
                    />
                </MobilePot>
            </Box>
            <Box
                id={`initAcceptRiseCoinPosition-${gameStore?.currentGameNew?._id}`}
                className={cn(classes.acceptRiseCoinPosition, {
                    moveAnimation: isLeftBet && isRightBet && !isWon,
                    hidePropose: isLeftBet && isRightBet && winner,
                })}
            >
                <CoinRiseComponent
                    isGamePage={!!isGamePage}
                    chessboardType={ChessboardType.mobileGame}
                    balanceType={UserBalanceType.play}
                    amountProp={memoRise || 0}
                />
            </Box>
        </>
    );
};

export default observer(RiseAndCoinInitiator);
