import React, { useEffect, useState } from "react";
import { usePageVisibility } from "react-page-visibility";

const useInitRematchTimer = (
    serverTime: string | number | undefined,
    timer: number
) => {
    const isVisible = usePageVisibility();
    const [actualTimer, setActualTimer] = useState<number>(timer);

    useEffect(() => {
        if (!serverTime || !timer) return;
        let serverTimeNumber =
            typeof serverTime === "number"
                ? serverTime
                : Date.parse(serverTime);
        let currentDate = +new Date();
        // const validTime = timer - (serverTimeNumber - currentDate);
        const validTime =
            serverTimeNumber - currentDate > timer
                ? timer
                : serverTimeNumber - currentDate;
        isVisible && validTime < 1
            ? setActualTimer(0)
            : setActualTimer(
                  Math.floor((serverTimeNumber - currentDate) / 1000)
              );
    }, [isVisible, serverTime]);

    return actualTimer;
};

export default useInitRematchTimer;
