import { action, makeAutoObservable } from "mobx";
import {
    acceptFriendRequest,
    addFriend,
    cancelFriendRequest,
    getFriends,
    rejectFriendRequest,
    removeFriend,
} from "src/service/api/friends";
import {
    IAcceptFriendRequest,
    IFriendActionRequest,
    IFriendDeleteRequest,
    IRejectFriendRequest,
    ICancelFriendRequest,
    IFriendAdd,
    IFriendRemove,
    IFriendActionResponse,
} from "src/service/api/friends/requestResponses";

import { IFriend } from "./models";

interface IFriendUpdateParams {
    online: boolean;
    isPlaying: boolean;
    rematchAwait?: boolean;
    joinAwait?: boolean;
    game: {
        id: string;
    };
}

export class FriendsStore {
    friends: IFriend[] = [];
    isLoaded: boolean = false;
    errorOccured: boolean = false;
    selectedFriend: IFriend | null = null;

    constructor() {
        makeAutoObservable(this);
    }
    @action
    async getFriends() {
        //try {
        //    this.setLoadedState(false);
        //    this.setIsErrorOccured(false);

        //    const response = await getFriends();
        //    const data = response.data as IFriend[];
        //    console.log('Friends data received:', data);

        //    if (Array.isArray(data)) {
        //        this.setFriends(data);
        //    } else {
        //        console.error("Received invalid friends data:", data);
        //        this.setFriends([]);
        //    }
        //} catch (e) {
        //    console.error('Error in getFriends:', e);
        //    this.setIsErrorOccured(true);
        //    this.setFriends([]);
        //} finally {
        //    this.setLoadedState(true);
        //}
    }

    @action
    async addFriend(addFriendParams: IFriendAdd) {
        this.setLoadedState(false);
        this.setIsErrorOccured(false);

        try {
            const res = await addFriend(addFriendParams);
            const data = res.data;
            if ("success" in data) {
                this.setLoadedState(true);
                return data;
            }
        } catch (e) {
            this.setIsErrorOccured(true);
            return e.response?.data || "Add friend error";
        } finally {
            this.setLoadedState(true);
        }
    }

    @action
    async removeFriend(removeFriendParams: IFriendRemove) {
        this.setLoadedState(false);
        this.setIsErrorOccured(false);

        try {
            const res = await removeFriend(removeFriendParams);
            const data = res.data;
            if ("success" in data) {
                this.setLoadedState(true);
                return data;
            }
        } catch (e) {
            this.setIsErrorOccured(true);
            return e.response?.data || "Remove friend error";
        } finally {
            this.setLoadedState(true);
        }
    }

    @action
    async acceptFriendRequest(params: IAcceptFriendRequest): Promise<void> {
        try {
            await acceptFriendRequest(params);
            await this.refreshFriends();
        } catch (e) {
            console.error('Error in acceptFriendRequest:', e);
            this.setIsErrorOccured(true);
        }
    }

    @action
    async rejectFriendRequest(params: IRejectFriendRequest): Promise<void> {
        try {
            await rejectFriendRequest(params);
            await this.refreshFriends();
        } catch (e) {
            console.error('Error in rejectFriendRequest:', e);
            this.setIsErrorOccured(true);
        }
    }

    @action
    async cancelFriendRequest(params: ICancelFriendRequest): Promise<void> {
        try {
            await cancelFriendRequest(params);
            await this.refreshFriends();
        } catch (e) {
            console.error('Error in cancelFriendRequest:', e);
            this.setIsErrorOccured(true);
        }
    }

    @action
    private async refreshFriends(): Promise<void> {
        try {
            await this.getFriends();
        } catch (e) {
            console.error('Error refreshing friends:', e);
            this.setIsErrorOccured(true);
        }
    }

    @action
    updateFriendById(friendId: string, params: Partial<IFriendUpdateParams>) {
        this.friends = this.friends
            .map((friend) => {
                if (friend.id === friendId) {
                    // Ensure that the game property is correctly updated
                    const updatedGame = params.game
                        ? { ...friend.game, ...params.game }
                        : friend.game;

                    return { ...friend, ...params, game: updatedGame };
                }
                return friend;
            })
            .sort((a, b) => {
                return a.online === b.online ? 0 : a.online ? -1 : 1;
            });
    }

    @action
    updateAfterDisconnect(friendId: string) {
        this.friends = this.friends
            .map((friend) => {
                if (friend.id === friendId) {
                    return friend.isPlaying
                        ? { ...friend, online: false, isPlaying: true }
                        : { ...friend, online: false, isPlaying: false };
                }
                return friend;
            })
            .sort((a, b) => {
                return a.online === b.online ? 0 : a.online ? -1 : 1;
            });
    }

    @action
    setLoadedState(isLoaded: boolean) {
        this.isLoaded = isLoaded;
    }

    @action
    setIsErrorOccured(isErrorOccured: boolean) {
        this.errorOccured = isErrorOccured;
    }

    @action
    setFriends(friends: IFriend[]) {
        if (Array.isArray(friends)) {
            this.friends = friends.map((friend) => ({
                ...friend,
                online: friend.online || false,
                isPlaying: friend.isPlaying || false,
            }));
        } else {
            console.error("Invalid friends data in setFriends:", friends);
            this.friends = [];
        }
        this.isLoaded = true;
    }

    @action
    setSelectedFriend(friend: IFriend) {
        this.selectedFriend = friend;
    }

    private async updateFriendsList() {
        try {
            await this.getFriends();
        } catch (e) {
            console.error('Error updating friends list:', e);
        }
    }
}

export default FriendsStore;
