import { useEffect, useMemo } from "react";

import { useLocale } from "src/providers/LocaleProvider";
import { GameFinishReason, EGameRematchStatus } from "src/store/models";

import { usePlayer } from "../../../sounds/use-player";
import useStores from "../../../hooks/useStores";

export const useWinOrLostStatus = () => {
    const {
        game: {
            winCauses: {
                baseMessage: winBaseMessage,
                timeout: winByTimeout,
                capture: winByCapture,
                resign: winByResign,
                inactivity: winByInactivity,
            },
            lossCauses: {
                baseMessage: lossBaseMessage,
                timeout: lossByTimeout,
                capture: lossByCapture,
                resign: lossByResign,
                inactivity: lossByInactivity,
            },
        },
    } = useLocale();
    const player = usePlayer();
    const { gameStore, authStore } = useStores();
    const winnerId = gameStore?.currentGameNew?.winner;
    const setting = gameStore?.gameSettings;
    const rematchInfo = gameStore.dialogs.rematchData;
    const me = authStore?.currentUser;
    const iAmInitiator = me?._id === rematchInfo.rematchStartedBy;
    const hideWinOrLostAlert =
        rematchInfo.rematchStatus === EGameRematchStatus.WAITING_FOR_ANSWER &&
        EGameRematchStatus.DECLINED;

    const iAmInitAcceptOrRejectDialog =
        rematchInfo.rematchStatus === EGameRematchStatus.WAITING_FOR_ANSWER &&
        iAmInitiator;
    const initForMeAcceptOrRejectDialog =
        rematchInfo.rematchStatus === EGameRematchStatus.WAITING_FOR_ANSWER &&
        !iAmInitiator;

    const myColor = useMemo(() => {
        return gameStore.getMyColorNew();
    }, [location.pathname, gameStore.gameId, gameStore?.currentGameNew?._id]);

    const opponentColor = useMemo(() => {
        return gameStore.getOpponentColorNew();
    }, [location.pathname, gameStore.gameId, gameStore?.currentGameNew?._id]);

    useEffect(() => {
        if (gameStore?.gameState?.isWon) {
            // Now turn off this because we have problem with playing sound which based on useEffects or mobx store, time after .Time we have old data in our store, or have dont actual data from backend and sound plays random or after reload page etc. At present we strive to play each sound only after user onclick if its posible
            // player('wonSoundPlay');
        } else {
            // player('lostSoundPlay');
        }
    }, []);
    // const isWon = gameStore?.gameState?.isWon;
    // const resultData = gameStore?.gameState?.gameResultData;
    // const setting = gameStore?.gameSettings;
    //====

    //Если игра закончилось и это мой ход, то я по идее сбил короля
    // Но если игра закончилась по времени, то по идее я проиграл

    const isMyMove = gameStore.isMyMove;
    const isTimersLeft = gameStore?.currentGameNew?.timeLeft.some(
        (time) => time === 0
    );
    const isWon = useMemo(() => {
        if (winnerId) {
            return winnerId === authStore.currentUser?._id
                ? myColor
                : opponentColor;
        }
        return false;
    }, [winnerId]);

    const isWonBoolean = useMemo(() => {
        if (winnerId) {
            return winnerId === authStore.currentUser?._id ? true : false;
        }
        return false;
    }, [winnerId]);
    // }, [gameStore?.gameState?.isOver]);
    // const resultData = { cause: GameFinishReason.STANDOFF };

    const winCauses = {
        baseMessage: winBaseMessage("Ты выйграл"),
        timeout: winByTimeout("Вы выиграли по времени"),
        capture: winByCapture("Вы съели короля"),
        resign: winByResign("Противник сдался"),
        inactivity: winByInactivity(
            "Вы выиграли из-за неактивности противника"
        ),
    };

    const lossCauses = {
        baseMessage: lossBaseMessage("Ты проиграл"),
        timeout: lossByTimeout("Вы проиграли по времени"),
        capture: lossByCapture("Соперник съел короля"),
        resign: lossByResign("Вы сдались"),
        inactivity: lossByInactivity("Вы проиграли по неактивности"),
    };

    const resultData = {
        winner: isWon,
        winnerBoolean: isWonBoolean,
        cause: GameFinishReason.BASEMESSAGE,
    };

    return {
        isWon,
        isWonBoolean,
        resultData,
        winCauses,
        lossCauses,
        setting,
        iAmInitAcceptOrRejectDialog,
        initForMeAcceptOrRejectDialog,
        hideWinOrLostAlert,
    };
};
