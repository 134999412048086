import React, { useEffect, useState, memo, useMemo, useCallback, useRef } from "react";
import moment from "moment";
import cn from "classnames";

import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import useChangeTheme from "src/hooks/useChangeTheme";

import { IGameChatBody } from "../types/IChat";
import { fileHelper } from "../../../../../shared/helpers/FileHelper/index";

import { useStyles } from "./styles";

//// Импорт SVG версий стикеров
//import stickerChtrSvg from 'src/assets/icons/stickers/cheater.svg';
//import stickerCrySvg from 'src/assets/icons/stickers/cry.svg';
//import stickerGGSvg from 'src/assets/icons/stickers/gg.svg';
//import stickerGLSvg from 'src/assets/icons/stickers/gl.svg';
//import stickerHeartSvg from 'src/assets/icons/stickers/heart.svg';
//import stickerRainbowSvg from 'src/assets/icons/stickers/rainbow.svg';
//import stickerWtfSvg from 'src/assets/icons/stickers/wtf.svg';
//import stickerDanceSvg from 'src/assets/icons/stickers/dance.gif';
//// Маппинг GIF к SVG
//const stickerSvgMap: { [key: string]: string } = {
//    'cheat.gif': stickerChtrSvg,
//    'cry.gif': stickerCrySvg,
//    'GG_02.gif': stickerGGSvg,
//    'Luck.gif': stickerGLSvg,
//    'love.gif': stickerHeartSvg,
//    'lgbt_03.gif': stickerRainbowSvg,
//    'wtf.gif': stickerWtfSvg,
//    'dance.gif': stickerDanceSvg,
//};

export const GameChatHistoryMessage = memo(
    ({
        children,
        time,
        position,
        getStickerMessage,
        isLastElem,
    }: IGameChatBody) => {

        const classes = useStyles({ position });
        const appearanceTheme = useChangeTheme();
        const CURRENT_FORMAT = "HH:mm:ss";
        const [isLoaded, setLoaded] = useState<boolean>(false);
        const [stickerSrc, setStickerSrc] = useState<string | null>(null);

        useEffect(() => {
            if (getStickerMessage) {
                const uniqueSrc = `${fileHelper.getFileURL(getStickerMessage)}?${Date.now()}`;

                setStickerSrc(null);
                setTimeout(() => {
                    setStickerSrc(uniqueSrc);
                    setLoaded(true);
                }, 10);
            }
        }, [getStickerMessage]);

        const momentTime = useMemo(() => {
            return moment(time).format(CURRENT_FORMAT);
        }, [time]);

        const formatMessage = (text) => {
            if (typeof text !== 'string') {
                console.error('Expected a string in formatMessage, received:', text);
                return null;
            }

            const regex = /\[img sticker="(.*?)"\]/g;
            const parts = text.split(regex);

            return parts.map((part, index) => {
                if (index % 2 === 1) {
                    return (
                        <img
                            key={index}
                            className={cn(classes.stickerImageWrapper, appearanceTheme, { isLoaded: true })}
                            src={part}
                            alt="sticker"
                        />
                    );
                }
                return <span key={index}>{part}</span>;
            });
        };

        const isStickerMessage = typeof children === 'string' && children.includes('[img sticker=');
        const isRightAligned = position === 'right' || position === 'rtl';

        return (
            <div className={cn(classes.messageRow, appearanceTheme)}>
                <div
                    className={cn(
                        classes.messageBlock,
                        appearanceTheme,
                        {
                            [classes.isSticker]: isStickerMessage,
                            isLastElem: isLastElem,
                        },
                        isStickerMessage ? (isRightAligned ? classes.stickerRight : classes.stickerLeft) : null
                    )}
                >
                    <div
                        className={cn(
                            position === "center"
                                ? classes.infoMessageText
                                : classes.playerMessageText,
                            appearanceTheme,
                            {
                                isSticker: isStickerMessage,
                                stickerRight: isStickerMessage && isRightAligned,
                                stickerLeft: isStickerMessage && !isRightAligned
                            }
                        )}
                    >
                        {getStickerMessage ? (
                            <>
                                {!isLoaded && (
                                    <div
                                        className={cn(
                                            classes.stckerSkeletonImageWrapper,
                                            appearanceTheme,
                                            {
                                                loadingSkeleton: !isLoaded,
                                            }
                                        )}
                                    ></div>
                                )}
                                <LazyImages
                                    key={Date.now()} 
                                    className={cn(
                                        classes.stickerImageWrapper,
                                        appearanceTheme,
                                        {
                                            isLoaded: isLoaded,
                                        }
                                    )}
                                    placeholderImg={avatarPlaceholder}
                                    src={stickerSrc || avatarPlaceholder} 
                                    errorImg={avatarPlaceholder}
                                    setLoaded={(status: boolean) => setLoaded(status)}
                                    onLoad={() => setLoaded(true)}
                                />
                            </>
                        ) : (
                            formatMessage(children)
                        )}
                    </div>
                </div>
            </div>
        );
    }
);