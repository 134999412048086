import React, { memo, useState, useLayoutEffect, useEffect } from "react";
import cn from "classnames";
import PulseLoader from "react-spinners/PulseLoader";

import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import { appColors, backgroundColors } from "src/consts/app-config";
import { IStickerPackList } from "src/store/models";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import { fileHelper } from "src/shared/helpers/FileHelper";

import { useStyles } from "./styles";

// Import stickers
import stickerChtr from 'src/assets/icons/stickers/cheat.gif';
import stickerCry from 'src/assets/icons/stickers/cry.gif';
import stickerGG from 'src/assets/icons/stickers/GG_02.gif';
import stickerGL from 'src/assets/icons/stickers/Luck.gif';
import stickerHeart from 'src/assets/icons/stickers/love.gif';
import stickerRainbow from 'src/assets/icons/stickers/lgbt_03.gif';
import stickerWtf from 'src/assets/icons/stickers/wtf.gif';
import stickerDance from 'src/assets/icons/stickers/dance.gif';
//Import svg stickers
import stickerChtrSvg from 'src/assets/icons/stickers/cheater.svg';
import stickerCrySvg from 'src/assets/icons/stickers/cry.svg';
import stickerGGSvg from 'src/assets/icons/stickers/gg.svg';
import stickerGLSvg from 'src/assets/icons/stickers/gl.svg';
import stickerHeartSvg from 'src/assets/icons/stickers/heart.svg';
import stickerRainbowSvg from 'src/assets/icons/stickers/rainbow.svg';
import stickerWtfSvg from 'src/assets/icons/stickers/wtf.svg';
import stickerDanceSvg from 'src/assets/icons/stickers/dance.png';

export interface IStickerContainer {
    onSendSticker?: (gifUrl: string, svgUrl: string) => void;
    stickerPack?: IStickerPackList;
}

const StickerContainer = ({
    onSendSticker,
}: IStickerContainer) => {
    const classes = useStyles();
    const { chatMobileType } = useLeftLayoutContext();
    const [skeletonState, setSkeletons] = useState<any[] | null>(null);
    const [isLoaded, setLoaded] = useState<boolean>(false);

    const stickers = [
        { id: 'sticker1', gif: stickerChtr, svg: stickerChtrSvg },
        { id: 'sticker2', gif: stickerCry, svg: stickerCrySvg },
        { id: 'sticker3', gif: stickerGG, svg: stickerGGSvg },
        { id: 'sticker4', gif: stickerGL, svg: stickerGLSvg },
        { id: 'sticker5', gif: stickerHeart, svg: stickerHeartSvg },
        { id: 'sticker6', gif: stickerRainbow, svg: stickerRainbowSvg },
        { id: 'sticker7', gif: stickerWtf, svg: stickerWtfSvg },
        { id: 'sticker8', gif: stickerDance, svg: stickerDanceSvg },
    ];

    const skeletonArray = () => {
        return Array.from({ length: 14 }, (_, i) => ({ id: i }));
    };

    useEffect(() => {
        if (skeletonState) return;
        const dataSkeleton = skeletonArray();
        setSkeletons(dataSkeleton);

        // Simulate loading time
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <React.Fragment key={`StickerContainer_mainFragment`}>
            {isLoaded && stickers.length > 0 && (
                <React.Fragment key={`stickers_maindivFragment`}>
                    <div
                        key={`stickers_mainDiv`}
                        className={cn(classes.stickersListBox, chatMobileType)}
                    >
                        <div
                            className={cn(classes.stickersList)}
                            key={`stickers_stickersList`}
                        >
                            {stickers.map((sticker) => (
                                <img
                                    key={sticker.id}
                                    className={cn(classes.stickersImg, {
                                        isLoaded: isLoaded,
                                    })}
                                    src={sticker.svg}
                                    alt={sticker.id}
                                    onClick={() => {
                                        console.log('Sticker clicked:', sticker.id);
                                        if (onSendSticker) {
                                            onSendSticker(sticker.gif, sticker.gif);  // Изменено: отправляем GIF вместо SVG
                                        } else {
                                            console.error('onSendSticker is not defined');
                                        }
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )}
            {!isLoaded && skeletonState && (
                <>
                    <div className={cn(classes.stickersTitle, "pulseLoader")}>
                        <PulseLoader
                            key={"pulse"}
                            color={appColors.semiwhite}
                            size={5}
                            margin="3px"
                        />
                    </div>
                    <div className={cn(classes.stickersList)}>
                        {skeletonState.map((item) => (
                            <span
                                key={item?.id}
                                className={cn(classes.stickersSkeleton, {
                                    loadingSkeleton: !isLoaded,
                                })}
                            />
                        ))}
                    </div>
                </>
            )}
        </React.Fragment>
    );
};

export default memo(StickerContainer);
