import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import classNames from "classnames";
import { useHistory, Link } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import {
    Box,
    Grid,
    OutlinedInput,
    InputLabel,
    FormControl,
    Dialog,
    createTheme,
    TextField,
} from "@mui/material";
import _get from "lodash/get";
import { observer } from "mobx-react";

import PhoneAuth from "src/dialogs/phone-auth/PhoneAuth";
import LocaleText from "src/components/locale-text/locale.text";
import { useLocale } from "src/providers/LocaleProvider";
import { ELanguages } from "src/store/models";

import { errorService } from "../../service/services";
import Checkbox from "../../components/controls/checkbox";
import { useStyles } from "../styles/styles.js";

import { useStyles as useMuiStyles } from "./styles";

import "./style.css";
import { ButtonType, GreenButton } from "../../components/buttons/greenButton";
import useStores from "../../hooks/useStores";

import closeIcon from "src/assets/icons/x.svg";
import googleLogo from "src/assets/icons/social/GoogleIcons.svg";
import appleLogo from "src/assets/icons/social/AppleIcons.svg";
import phoneLogo from "src/assets/icons/social/phone.svg";
import facebookLogo from "src/assets/icons/social/fbIcons.svg";
import { useModal } from "src/pages/landing/utils/context";
import paths from "src/consts/paths";

import { GoogleLogin } from "@react-oauth/google";
import { MainActionButton } from "../../components/buttons/newDesignButtons/mainActionButton";
import eyeCloseIcon from "src/assets/icons/EyeClose.svg";
import eyeOpenIcon from "src/assets/icons/EyeOpen.svg";

const theme = createTheme({
    palette: {
        primary: {
            main: "#5B627E",
            dark: "#fff",
            contrastText: "#5B627E",
            light: "#5B627E",
        },
    },
});


const LoginForm = () => {
    const [remember, setRemember] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);
    const inputEl = useRef<any>(null);
    const { authStore, generalStore } = useStores();
    const muiStyles = useMuiStyles();
    const { closeModal, openModal } = useModal();

    const [eyeClosedIcon, setEyeClosedIcon] = useState(eyeCloseIcon);
    const [eyeOpenedIcon, setEyeOpenedIcon] = useState(eyeOpenIcon);

    useEffect(() => {
        setEyeClosedIcon(eyeCloseIcon);
        setEyeOpenedIcon(eyeOpenIcon);
    }, []);

    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setIsPasswordHidden(!isPasswordHidden);
    };

    //const onButtonClick = (e) => {
    //    e.preventDefault();
    //    const el = inputEl.current;
    //    if (!el) return;
    //    const input = el.querySelector("#password");
    //    if (!input) return;
    //    if (isPasswordHidden) {
    //        setVisiblePassword(false);
    //        input.type = "text";
    //    } else {
    //        setVisiblePassword(true);
    //        input.type = "password";
    //    }
    //};

    useEffect(() => {
        if (authStore.isAuthorized) history.push(paths.lobby);
    }, [authStore.isAuthorized]);

    const history = useHistory();
    const classes = useStyles();

    const {
        login: {
            errors: {
                useJustEnglishLetters,
                tooLongPassword,
                enterThePassword,
                tooLongEmail,
                enterEmail,
            },
            form: { placeEmail, placePassword },
            loginButton,
            rememberMe,
            forgotPassword,
            or,
            dontHaveAccount,
            passwordHint,
            signUp,
        },
    } = useLocale();

    const locales = useLocale();

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .trim()
            .matches(
                /[a-zA-Z\d\s|!#$%^&*()_}{"'`~+,-\[\]]/g,
                useJustEnglishLetters("Use only English letters")
            )
            .max(
                20,
                tooLongPassword("The password is very long (max. 20 characters)")
            )
            .required(enterThePassword("Enter your password")),
        email: Yup.string()
            .max(64, tooLongEmail("The email is too long"))
            .required(enterEmail("Enter your email address")),
    });

    const onSubmitForm = async ({ email, password }): Promise<boolean> => {
        setLoading(true);
        try {
            const error = await authStore.signIn(
                email,
                password,
                authStore.selectedLanguage || ELanguages.EN,
                remember
            );
            if (error) {
                setError(true);
                errorService.sendError(error);
                return false;
            } else {
                await authStore.getMeNew();
                history.push(paths.lobby);
                closeModal("login");
                return true;
            }
        } catch (err) {
            const error = err?.response?.data?.error;
            if (error) {
                errorService.sendError(
                    _get(
                        locales,
                        error.locale?.label
                    )(error.locale.defaultValue || "")
                );
            }
            return false;
        } finally {
            setLoading(false);
        }
    };

    if (authStore.isAuthorized) {
        return null;
    }

    const handleSuccessfulLogin = () => {
        closeModal("login");
        history.push(paths.jackpoker);
    };

    return (
        <Dialog
            open
            className={muiStyles.container}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    closeModal("login");
                }
            }}
            disableEscapeKeyDown
            PaperProps={{ className: muiStyles.dialogPaper }}
        >
            <Box component="div" className={muiStyles.formContainer}>
                <ThemeProvider theme={theme}>
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            const success = await onSubmitForm(values);
                            setSubmitting(false);
                            if (success) {
                                closeModal("login");
                            }
                        }}
                        render={({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                        }) => {
                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    className={muiStyles.formWrapper}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <Grid
                                            xs={12}
                                            item
                                            className={muiStyles.titleGridItem}
                                        >
                                            <div
                                                className={muiStyles.titleBlock}
                                            >
                                                <div
                                                    className={
                                                        muiStyles.enterGame
                                                    }
                                                >
                                                    <LocaleText
                                                        label="login.title"
                                                        defaultValue="Log In"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        muiStyles.closeIconWrapper
                                                    }
                                                >
                                                    <img
                                                        src={closeIcon}
                                                        alt="Close"
                                                        className={
                                                            muiStyles.closeIcon
                                                        }
                                                        onClick={() =>
                                                            closeModal("login")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={classNames(
                                                    muiStyles.joinGame,
                                                    "joinGame joinGame--login"
                                                )}
                                            ></div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className="fieldGrid"
                                        >
                                            <div className="OutlinedInput">
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    className="input-container"
                                                >
                                                    <label
                                                        htmlFor="email"
                                                        className={muiStyles.outlinedLabel}
                                                    >
                                                        <LocaleText
                                                            label="login.emailLabel"
                                                            defaultValue="Email*"
                                                        />
                                                    </label>
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        onChange={handleChange}
                                                        name="email"
                                                        value={values.email}
                                                        className={`${muiStyles.outlinedInput} ${muiStyles.inputAutoFillOverride} ${errors.email ? muiStyles.inputError : ''}`}
                                                        placeholder={placeEmail("Enter your email address")}
                                                        required
                                                    />
                                                    {errors.email && (
                                                        <div className="error">
                                                            <LocaleText
                                                                label={`login.errors.email.${errors.email}`}
                                                                defaultValue={errors.email}
                                                            />
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid
                                            xs={12}
                                            item
                                            className="fieldGrid"
                                        >
                                            <div className="OutlinedInput">
                                                <div className="inputWrapper">

                                                </div>
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <label
                                                        htmlFor="password"
                                                        className={
                                                            muiStyles.outlinedLabel
                                                        }
                                                    >
                                                        <LocaleText
                                                            label="login.passwordLabel"
                                                            defaultValue="Password*"
                                                        />
                                                    </label>

                                                    <div className={muiStyles.passwordInputWrapper}>
                                                        <input
                                                            ref={inputEl}
                                                            id="password"
                                                            onChange={handleChange}
                                                            name="password"
                                                            type={isPasswordHidden ? "password" : "text"}
                                                            value={values.password}
                                                            className={`${muiStyles.outlinedInput} ${muiStyles.inputAutoFillOverride} ${errors.password ? muiStyles.inputError : ''}`}
                                                            placeholder={placePassword("Enter your password")}
                                                            required
                                                        />
                                                        <button
                                                            className={muiStyles.hidePasswordBtn}
                                                            onClick={togglePasswordVisibility}
                                                            type="button"
                                                        >
                                                            <img
                                                                src={isPasswordHidden ? eyeClosedIcon : eyeOpenedIcon}
                                                                alt={isPasswordHidden ? "Show password" : "Hide password"}
                                                            />
                                                        </button>
                                                    </div>

                                                    <div
                                                        className={
                                                            muiStyles.passwordHint
                                                        }
                                                    >
                                                        <LocaleText
                                                            label="login.passwordHint"
                                                            defaultValue={passwordHint(
                                                                "Password must be at least 8 characters long"
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.password && (
                                                        <div className="error">
                                                            <LocaleText
                                                                label={`login.errors.password.${errors.password}`}
                                                                defaultValue={
                                                                    errors.password as string
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid
                                            xs={12}
                                            item
                                            className="fieldGrid"
                                        >
                                            <div
                                                className={
                                                    muiStyles.checkboxField
                                                }
                                            >
                                                <div
                                                    className={
                                                        muiStyles.checkboxContainer
                                                    }
                                                    onClick={() =>
                                                        setRemember(!remember)
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className={
                                                            muiStyles.checkboxInput
                                                        }
                                                        checked={remember}
                                                        readOnly
                                                    />
                                                    <span
                                                        className={classNames(
                                                            muiStyles.checkmark,
                                                            {
                                                                [muiStyles.checkmarkChecked]:
                                                                    remember,
                                                            }
                                                        )}
                                                    />
                                                    <span
                                                        className={
                                                            muiStyles.checkboxText
                                                        }
                                                    >
                                                        <LocaleText
                                                            label="login.rememberMe"
                                                            defaultValue={rememberMe(
                                                                "Remember me"
                                                            )}
                                                        />
                                                    </span>
                                                </div>
                                                <Link
                                                    to="/recovery-password"
                                                    className={classNames(
                                                        classes.link,
                                                        muiStyles.recoveryPasswordText
                                                    )}
                                                    onClick={() =>
                                                        closeModal("login")
                                                    }
                                                >
                                                    <LocaleText
                                                        label="login.forgotPasswordButton"
                                                        defaultValue={forgotPassword(
                                                            "Forgot Password"
                                                        )}
                                                    />
                                                </Link>
                                            </div>
                                        </Grid>

                                        {error && (
                                            <Grid xs={12} item className="fieldGrid">
                                                <div className="joinGame joinGame--notFound">
                                                    <LocaleText
                                                        label="login.accountNotFound"
                                                        defaultValue="Account not found"
                                                    />
                                                    &nbsp;
                                                    <button
                                                        onClick={() => {
                                                            closeModal("login");
                                                            openModal("register");
                                                        }}
                                                        className={muiStyles.signUp}
                                                    >
                                                        <LocaleText
                                                            label="login.createAccount"
                                                            defaultValue="Create Account"
                                                        />
                                                    </button>
                                                </div>
                                            </Grid>
                                        )}

                                        <Grid
                                            item
                                            xs={12}
                                            className="fieldGrid"
                                        >
                                            <div
                                                className={classNames(
                                                    muiStyles.formBtnWrap,
                                                    "formBtnWrap--login"
                                                )}
                                            >
                                                <MainActionButton
                                                    loading={isLoading}
                                                    className={
                                                        muiStyles.logInBtn
                                                    }
                                                    buttonType={"submit"}
                                                    text={loginButton(
                                                        "Continue"
                                                    )}
                                                    onClick={() => { }}
                                                    appearanceTheme={""}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        className="fieldGrid"
                                        alignContent={"flex-start"}
                                    >
                                        <Grid
                                            container
                                            className={muiStyles.lineContainer}
                                        >
                                            <div className={muiStyles.lineBox}>
                                                <LocaleText
                                                    label="login.or"
                                                    defaultValue={or("or")}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        className="fieldGrid"
                                        alignContent={"flex-start"}
                                    >
                                        <Grid
                                            container
                                            className={muiStyles.socialRegister}
                                            style={{ display: "flex" }}
                                        >
                                            <div
                                                className={
                                                    muiStyles.googleButtonWrapper
                                                }
                                            >
                                                <button
                                                    className={
                                                        muiStyles.socialRegisterBtn
                                                    }
                                                    style={{ zIndex: 1 }}
                                                >
                                                    <img
                                                        src={googleLogo}
                                                        alt="Google"
                                                        className={
                                                            muiStyles.socialIcon
                                                        }
                                                    />
                                                </button>
                                                <div
                                                    className={
                                                        muiStyles.googleButtonOverlay
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            muiStyles.googleButtonInner
                                                        }
                                                    >
                                                        <GoogleLogin
                                                            onSuccess={async (
                                                                credentialResponse
                                                            ) => {
                                                                if (
                                                                    credentialResponse.credential
                                                                ) {
                                                                    await authStore.signInWithGoogle(
                                                                        credentialResponse.credential
                                                                    );
                                                                } else {
                                                                    console.error(
                                                                        "Google sign-in error: credential is undefined"
                                                                    );
                                                                }
                                                            }}
                                                            onError={() => {
                                                                console.log(
                                                                    "Login Failed"
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div*/}
                                            {/*    className={*/}
                                            {/*        muiStyles.appleButtonWrapper*/}
                                            {/*    }*/}
                                            {/*>*/}
                                            {/*    <button*/}
                                            {/*        className={*/}
                                            {/*            muiStyles.socialRegisterBtn*/}
                                            {/*        }*/}
                                            {/*    >*/}
                                            {/*        <img*/}
                                            {/*            src={appleLogo}*/}
                                            {/*            alt="Apple"*/}
                                            {/*            className={*/}
                                            {/*                muiStyles.socialIcon*/}
                                            {/*            }*/}
                                            {/*        />*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                            {generalStore.isHalyk && (
                                                <div
                                                    className={
                                                        muiStyles.phoneButtonWrapper
                                                    }
                                                >
                                                    <button
                                                        className={
                                                            muiStyles.socialRegisterBtn
                                                        }
                                                        onClick={() => {
                                                            closeModal("login");
                                                            openModal("phone-auth");
                                                        }}
                                                    >
                                                        <img
                                                            src={phoneLogo}
                                                            alt="Phone"
                                                            className={
                                                                muiStyles.socialIcon
                                                            }
                                                        />
                                                    </button>
                                                </div>
                                            )}
                                           
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        className="fieldGrid"
                                        alignContent={"flex-start"}
                                    >
                                        <Grid
                                            container
                                            className={muiStyles.signUpBlock}
                                        >
                                            <p>
                                                <LocaleText
                                                    label="login.noAccount"
                                                    defaultValue={dontHaveAccount(
                                                        "Don't have an account?"
                                                    )}
                                                />
                                            </p>
                                            <button
                                                className={muiStyles.signUp}
                                                onClick={() => {
                                                    closeModal("login");
                                                    openModal("register");
                                                }}
                                            >
                                                <LocaleText
                                                    label="login.signUp"
                                                    defaultValue={signUp(
                                                        "Sign Up"
                                                    )}
                                                />
                                            </button>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    />
                </ThemeProvider>
            </Box>
        </Dialog>
    );
};

export default observer(LoginForm);
