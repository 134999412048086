import { makeStyles } from "@mui/styles";

import theme from "src/theme";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes x2DepositLogoAnimate": {
            "0%": {
                transform: "scale(1)",
            },
            "50%": {
                transform: "scale(0.8)",
            },
            "100%": {
                transform: "scale(1)",
            },
        },
    },

    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    },


    containerLobby: {
        width: "100vw",
        height: "100%",
        margin: "0 auto",
        fontFamily: "Sans Caption",
        position: "fixed",
        top: 0,
        left: "50%",
        transform: "translate(-50%, 0%)",
    },
    "*": {
        boxSizing: "border-box",
    },
    menu: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "20vw",
        justifyContent: "space-between",
        backgroundColor: "#202224",
        gap: "1.04vw",
        padding: "1.2vw 1.4vw 1.4vw 1.2vw",

        "@media (max-width: 1300px)": {
            width: "30vw",
            gap: "5vw",
        },
        "@media (max-width: 1210px)": {
            width: "34vw",
            gap: "5vw",
        },

        "@media (max-width: 1150px)": {
            width: "36vw",
            gap: "5vw",
        },
        "@media (max-width: 1090px)": {
            width: "38vw",
            gap: "5vw",
        },
    },

    menuHalyk: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "17vw",
        justifyContent: "space-between",
        backgroundColor: "#202224",
        gap: "1.04vw",
        padding: "1.2vw 1.4vw 1.4vw 1.2vw",

        "@media (max-width: 1300px)": {
            width: "30vw",
            gap: "5vw",
        },
        "@media (max-width: 1210px)": {
            width: "34vw",
            gap: "5vw",
        },

        "@media (max-width: 1150px)": {
            width: "36vw",
            gap: "5vw",
        },
        "@media (max-width: 1090px)": {
            width: "38vw",
            gap: "5vw",
        },
    },

    content: {
        flexGrow: "1",
        overflowY: "auto",
        scrollbarWidth: "none",
    },

    "content::-webkit-scrollbar": {
        display: "none",
    },

    navMenuItemBox: {
        zIndex: 11,
        width: "fit-content",
        height: "fit-content",
        //position: "absolute",
        top: "1.2vw",
        left: "1.2vw",
        paddingLeft: 0,
        cursor: "pointer",
        "&:hover $bonusDescriptionModal": {
            opacity: 1,
            visibility: "visible",
        },
    },

    gameInfoDot: {
        // Стили для gameInfoDot
    },

    bonusDescriptionModal: {},

    logoIcon: {
        maxWidth: "14.7vw",
        width: "100%",
        objectFit: "contain",
        "@media (max-height: 800px)": {
            maxWidth: "11.8vw",
        },
    },
    menuLinks: {
        width: "14.5vw",
        height: "auto",
        gap: "1vw",
    },
    menuLink: {
        position: "relative",
        display: "flex",
        marginBottom: "0.65vw",
        alignItems: "center",
        cursor: "pointer",
        "@media (max-height: 1000px)": {
            marginBottom: "0.65vw",
        },
        "@media (max-height: 880px)": {
            marginBottom: "0.65vw",
        },
        "&:hover img:first-child": {
            opacity: 0,
            transition: "opacity 0.3s, ease-in-out",
        },
        "&:hover p": {
            transform: "scale(1.2)",
            transition: "0.3s ease-in-out",
            fontWeight: 600,
            marginLeft: "0.4vw",
        },
        "img:last-child": {
            width: "67px",
            height: "66px",
        },
        "&:hover img:last-child": {
            opacity: 1,
            transition: "opacity 0.5s, ease-in-out",
        },
    },
    mainIcon: {
        position: "relative",
    },
    linkIcon: {
        position: "inherit",
        width: "4vw",
        marginRight: "0.8vw",
        marginLeft: "0.5vw",
        zIndex: 20,
        "@media (max-height: 740px)": {
            width: "3vw",
        },
    },

    vSportIcon: {
        width: "2vw",
        marginRight: "1.5vw",
        marginLeft: "0.7vw",
        marginTop: "0.7vw",
    },

    link: {
        color: "white",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 600,
        fontSize: "1vw",
        lineHeight: "1.5vw",
        zIndex: 5,
        margin: 0,
        transition: "0.3s ease-in-out",
        "@media (max-width: 2000px)": {
            fontSize: "1.2vw",
        },
        "@media (min-width: 1700px)": {
            fontSize: "1.2vw",
        },
        "@media (max-width: 1310px)": {
            fontSize: "1.1vw",
        },
        "@media (max-height: 830px)": {
            fontSize: "1.2vw",
        },
    },
    lightIcon: {
        width: "4.1vw",
        position: "absolute",
        left: 0,
        bottom: "0.3vw",
        zIndex: 0,
        opacity: 1,
        "@media (max-height: 740px)": {
            width: "2.6vw",
        },
    },
    blurOnHover: {
        opacity: 0,
        width: "120%",
        position: "absolute",
        left: "50%",
        top: "50%",
        zIndex: 0,
        transition: "opacity 0.3s, ease-in-out",
        transform: "translate(-50%, -50%)",
    },
    menuFooter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#151719",
        padding: "1.2vw",
        borderRadius: "0.5vw",
        marginBottom: "1.2vw",
        maxWidth: "100%",
    },
    /*
    languageWrapper: {
        marginLeft: "0.6vw",
        padding: "0.3vw 0.9vw",
        background: "rgb(22,56,19)",
    },   
    */
    socialIcons: {
        display: "flex",
        gap: "2vw",
        justifyContent: "center",
    },

    folowIcon: {
        width: 20,
        height: 20,
        cursor: 'pointer',
    },

    socialIcon: {
        objectFit: "cover",
        width: "1vw",
        marginRight: "0",
    },

    socialBlock: {
        gap: "1vw",
        marginTop: "auto",
        padding: "1vw",
        position: "fixed",
        bottom: "0",
    },

    main: {
        width: "100%",
        position: "relative",
        height: "100vh",
        backgroundColor: "#151719",
        display: "flex",
    },

    heroSection: {
        width: "100%",
        display: "flex",
        position: "relative",
        background: "#151719",
        flexDirection: "column-reverse",
        flexWrap: "nowrap",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 0,
    },

    heroSectionHalyk: {
        width: "100%",
        display: "flex",
        position: "relative",
        background: "#151719",
        flexDirection: "column-reverse",
        flexWrap: "nowrap",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 0,
    },

    heroVideo: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },

    playButton: {
        borderRadius: "0.2vw",
        border: "none",
        background: "#00BA6C",
        fontSize: "1.2vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        padding: "0.7vw 0.9vw",
        color: "#11141C",
        cursor: "pointer",
        position: "absolute",
        marginBottom: "1.2vw",
        "&:hover": {
            opacity: 0.9,
        },

        "@media (max-height: 915px)": {
            fontSize: "1.2vw",
            padding: "0.7vw 0.9vw",
        },
    },

    underHeroContent: {
        background: "#151719",
        padding: '24px 18px',
        gap: 24,
        display: "flex",
        justifyContent: "center",
    },

    underHeroContentHalyk: {
        background: "#151719",
        gap: 24,
        display: "flex",
        justifyContent: "center",
        padding: '1.2vw 1.2vw',
        //margin: '0 1vw',
    },

    underHeroVideoHalyk: {
        background: "#151719",
        gap: 24,
        display: "flex",
        justifyContent: "center",
        //margin: '0 1vw',
    },

    pokerSlots: {
        width: '100%',
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#2E3136",
        padding: 13,
        borderRadius: 16,
        boxShadow: "0 0.2vw 0.6vw rgba(0, 0, 0, 0.1)",
    },

    pockerImage: {
        width: "36vw",
        height: "20vw",
        objectFit: "cover",
        borderRadius: 8,
        marginBottom: "1.2vw",
    },

    pockerImageHalyk: {
        width: "38vw",
        height: "20vw",
        objectFit: "cover",
        borderRadius: 8,
        marginBottom: "1.2vw",
    },

    imageWrapper: {
        position: "relative",
    },

    arrow: {
        position: "absolute",
        top: "45%",
        transform: "translateY(-50%)",
        width: "90%",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
    },
    leftArrow: {},
    rightArrow: {
        transform: "rotate(180deg)",
    },
    button: {
        background: "#2e3136",
        color: "#00BA6C",
        padding: "0.7vw 0.9vw",
        border: "0.1vw solid #00BA6C",
        borderRadius: 2,
        cursor: "pointer",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 700,
        fontSize: "1vw",
        lineHeight: "1.5vw",
        width: "100%",
        height: "2.8vw",
    },

    slotsContainer: {
        backgroundColor: "#151719",
        padding: "1.2vw 1.2vw",
    },

    slotsTitle: {
        color: "#F5F5F5",
        fontSize: 30,
        fontFamily: "Montserrat",
        lineHeight: "39.01px",
        fontWeight: "600",
        marginBottom: "0",
        padding: "0.5vw 0.5vw 0.5vw 0",
        //styleName: Desktop/Header 2;

    },

    slotsGrid: {
        display: "flex",
        flexWrap: "wrap",
        gap: "1vw",
        "& > *:nth-child(n+25)": { // Скрываем все элементы после 24-го (4 ряда по 6)
            display: "none",
        },
    },

    slotImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover", //or cover
        borderRadius: "0.5vw",
    },

    slotItem: {
        cursor: "pointer",
        width: "calc(16.666% - 0.8333vw)", 
        height: "7.6vw", 
        "&:nth-child(6n)": {
            marginRight: 0
        },
        "@media (max-width: 1920px)": {
            width: "calc(16.666% - 0.8333vw)",
            height: "7.6vw",
            "&:nth-child(6n)": {
                marginRight: 0
            },
            "&:nth-child(n+25)": {
                display: 'none'
            },
        },
        "@media (max-width: 1400px)": {
            width: "calc(25% - 0.75vw)", // 4 слота в ряду для меньших экранов
            height: "12vw",
            marginBottom: "1vw",
            "&:nth-child(4n)": {
                marginRight: 0
            },
            "&:nth-child(n+17)": {
                display: 'none'
            },
        }
    },

    slotBody: {
        position: "relative",
        height: "100%",
    },

    slotsHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1.2vw",
        padding: "0",
    },

    viewAllButton: {
        marginTop: "1.4vw",
        fontSize: "1.2vw",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        fontWeight: "700",
        color: "#00BA6C",
        background: "transparent",
        border: "none",
    },

    line: {
        width: "100%",
        border: "0.1vw solid rgba(255, 255, 255, 0.1)",
    },

    liveCasinoBlock: {
        backgroundColor: "#151719",
        padding: "1.2vw 1.2vw",
    },

    streamChannelsBlock: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        backgroundColor: "#151719",
        padding: "1.2vw",
        borderRadius: "0.5vw",
        //marginBottom: "1.2vw",
        width: "100%",
    },
    streamGridContainer: {
        width: "100%",
        overflowX: "auto",
        scrollbarWidth: "none", 
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": { 
            display: "none",
        },
    },
    streamGrid: {
        display: "flex",
        gap: "1vw",
        paddingBottom: "1vw",
        paddingRight: "1vw",
        width: "max-content",
    },
    streamHeader: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: "1.2vw",
    },
    streamTitle: {
        color: "#F5F5F5",
        fontSize: "30px",
        fontFamily: "Montserrat",
        lineHeight: "39.01px",
        fontWeight: "600",
        marginBottom: "0",
    },
    streamItem: {
        flex: "0 0 180px",
        width: "180px",
        backgroundColor: "#1E2022",
        borderRadius: "10px",
        overflow: "hidden",
    },
    streamImageContainer: {
        width: "100%",
        aspectRatio: "16 / 9",
        padding: "10px 10px 0 10px", 
        boxSizing: "border-box",
        fontFamily: 'Montserrat',
    },
    streamImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: 8,
    },
    streamInfo: {
        padding: "10px",
    },
    streamName: {
        color: "#F5F5F5",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: "600",
        margin: "0 0 5px 0",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    streamStatus: {
        fontSize: "12px",
        fontFamily: "Montserrat",
        display: "flex",
        alignItems: "center",
        margin: "0 0 10px 0",
    },
    online: {
        color: "#00BA6C",
        "&::before": {
            content: '""',
            display: "inline-block",
            width: "6px",
            height: "6px",
            backgroundColor: "#00BA6C",
            borderRadius: "50%",
            marginRight: "6px",
        },
    },
    offline: {
        color: "#808080",
        "&::before": {
            content: '""',
            display: "inline-block",
            width: "6px",
            height: "6px",
            backgroundColor: "#808080",
            borderRadius: "50%",
            marginRight: "6px",
        },
    },
    streamButtons: {
        display: "flex",
        gap: "8px",
        justifyContent: 'space-around',
    },
    streamButton: {
        width: "100%",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #00BA6C",
        borderRadius: "4px",
        transition: "background-color 0.3s",
        "&:hover": {
            backgroundColor: "#3A3C3E",
        },
    },
    streamIcon: {
        width: "16px",
        height: "16px",
    },

    streamSchedule: {
        // Добавьте стили для расписания стрима
        fontSize: '0.8rem',
        color: '#888',
        marginTop: '5px',
    },

    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "0.6vw",
        width: "100%",
        marginTop: "auto",
        position: "relative",
        zIndex: 1,
    },

    disabledButton: {
        backgroundColor: "#808080",
        cursor: "not-allowed",
    },

    streamSlots: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(245, 245, 245, 0.05)",
        padding: 6,
        borderRadius: "0.9vw",
        boxShadow: "0 0.2vw 0.6vw rgba(0, 0, 0, 0.1)",
        width: "100%",
        aspectRatio: "16/9",
        position: "relative",
    },
    background: {
        position: "absolute",
        height: "100%",
        width: "100%",
        objectFit: "cover",
        zIndex: -1,
    },
    header: {
        background: "#151719",
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0.5vw",
        zIndex: 9,
        justifyContent: "space-between",
    },
    dices: {
        right: "8%",
        position: "absolute",
        width: "75%",
        height: "100%",
        "@media (min-width: 1200px)": {
            width: "66%",
            right: "12%",
        },
        "@media (min-width: 1300px)": {
            width: "calc(60% - 0vw)",
            right: "14%",
        },
        "@media (min-width: 1380px)": {
            width: "calc(51% - 0vw)",
            right: "18%",
        },
        "@media (min-width: 1480px)": {
            width: "calc(58% - 4.1vw)",
            right: "23%",
        },
        "@media (min-width: 1590px)": {
            width: "calc(47% - 2.3vw)",
            right: "25%",
        },
        "@media (min-width: 1800px)": {
            width: "calc(50% - 8.8vw)",
        },
        "@media (min-width: 2000px)": {
            width: "calc(50% - 11.2vw)",
        },
    },
    dice: {
        width: "15.3vw",
        position: "absolute",
        bottom: "48%",
        left: "50%",
        transform: "translateX(-8.5vw)",
        filter: "blur(0.1vw)",
        "@media (max-height: 2000px)": {
            scale: 1.3,
            bottom: "30%",
        },
        "@media (max-height: 1700px)": {
            bottom: "36%",
        },
        "@media (max-height: 1050px) and (max-width: 2000px)": {
            scale: 1,
            bottom: "30%",
            left: "40%",
        },
        "@media (max-height: 900px) and (max-width: 1600px)": {
            bottom: "30%",
        },
        "@media (max-height: 850px) and (min-width: 1500px)": {
            scale: 0.9,
            bottom: "28%",
        },
        "@media (max-width: 2300px)": {
            transform: "translateX(-8.5vw)",
        },
        "@media (max-height: 700px)": {
            transform: "translateX(-5.9vw)",
        },
    },
    leftDice: {
        width: "7.6vw",
        position: "absolute",
        left: "0%",
        top: "9%",
        "@media (max-height: 2000px)": {
            top: "45%",
            scale: 1.3,
        },
        "@media (max-height: 1900px)": {
            top: "35%",
        },
        "@media (max-height: 1700px)": {
            top: "25%",
        },
        "@media (max-height: 1150px)": {
            top: "25%",
        },
        "@media (max-height: 1050px) and (max-width: 2000px)": {
            scale: 1,
        },
        "@media (max-height: 700px)": {
            top: "15%",
        },
    },
    rightDice: {
        width: "10vw",
        position: "absolute",
        right: "0%",
        top: "9%",
        filter: "blur(0.1vw)",
        "@media (max-height: 2000px)": {
            top: "40%",
            scale: 1.3,
        },
        "@media (max-height: 1700px)": {
            top: "30%",
        },
        "@media (max-height: 1450px)": {
            top: "25%",
        },
        "@media (max-height: 1150px)": {
            top: "15%",
        },
        "@media (max-height: 1050px) and (max-width: 2000px)": {
            scale: 1,
            right: "15%",
        },
        "@media (max-height: 950px) and (min-width: 1500px)": {
            top: "15%",
            right: "10%",
        },
        "@media (max-height: 850px) and (min-width: 1500px)": {
            scale: 0.9,
        },
        "@media (max-height: 700px)": {
            top: "7%",
        },
    },

    lobbyTitle: {
        position: "relative",
        bottom: 0,
        display: "flex",
        justifyContent: "space-between",
        color: "white",
        zIndex: 10,
        flexDirection: "column",
    },
    firstTitle: {
        fontSize: "3.1vw",
        fontWeight: 400,
        margin: 0,
        textShadow: "0.5vw 0.3vw 0.4vw rgba(0,0,0,0.75)",
        marginLeft: "1.8vw",
        "@media (min-width: 1700px)": {
            fontSize: "3.4vw",
        },
        "@media (max-width: 1310px)": {
            fontSize: "2.8vw",
        },
        "@media (max-width: 1190px)": {
            fontSize: "2.4vw",
        },
        "@media (max-height: 830px)": {
            fontSize: "2.2vw",
        },
    },
    chessTitle: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "4.7vw",
        fontWeight: 400,
        marginTop: "-0.4vw",
        textShadow: "0.5vw 0.3vw 1.7vw rgba(0,0,0,0.75)",
        marginLeft: "1.8vw",
        marginBottom: "2.9vw",
        "@media (min-width: 1700px)": {
            fontSize: "5.1vw",
        },
        "@media (max-width: 1310px)": {
            fontSize: "4.5vw",
        },
        "@media (max-width: 1190px)": {
            fontSize: "4vw",
        },
        "@media (max-height: 830px)": {
            fontSize: "3.9vw",
        },
    },

    followUs: {
        fontSize: "1.2vw",
        fontWeight: 600,
        color: "#F5F5F5",
        fontFamily: "Montserrat",
        lineHeigh: "1.8vw",
        verticalTrim: "Cap height",
        padding: "0 0 1.1vw 0",
    },

    adv: {
        display: "flex",
        alignItems: "center",
        color: "white",
        padding: "0.9vw 0.6vw 0.9vw 2.4vw",
        fontSize: "1.5vw",
        letterSpacing: "0.1vw",
        background:
            "linear-gradient(90deg, rgba(16,18,17,1) 1.44%, rgba(22,56,19,1) 98.62%)",
        paddingLeft: "2.4vw",
        "@media (max-width: 1310px)": {
            fontSize: "1.2vw",
        },
    },

    phone: {
        height: "23.5vw",
        "@media (max-width: 1710px)": {
            height: "22.9vw",
        },
        "@media (max-width: 1600px) and (max-height: 900px)": {
            height: "20vw",
        },
        "@media (max-width: 1600px) and (max-height: 1200px)": {
            height: "22.9vw",
        },
        "@media (max-width: 1510px)": {
            height: "20vw",
        },
        "@media (max-width: 1410px)": {
            height: "17.6vw",
        },
        "@media (max-width: 1310px)": {
            height: "16.2vw",
        },
        "@media (max-height: 830px)": {
            maxHeight: "14.7vw",
        },
        "@media (max-height: 730px)": {
            maxHeight: "13.5vw",
        },
        "@media (max-height: 700px)": {
            maxHeight: "12.9vw",
        },
    },
    app: {
        width: "30%",
        fontSize: "1.1vw",
        alignSelf: "end",
        marginBottom: "0.6vw",
        "@media (max-width: 1310px)": {
            fontSize: "1.1vw",
            padding: "0.9vw 0.6vw 0.6vw 0.6vw",
            textAlign: "end",
        },
        "@media (max-width: 1150px)": {
            width: "50%",
        },
        "&.support": {
            position: "absolute",
            top: 0,
            right: "0.6vw",
            width: "fit-content",
            padding: "0.6vw 1.5vw",
            margin: "0",
            borderRadius: "0.6vw",
            [theme.breakpoints.down("xl")]: {
                right: "0.6vw",
            },
        },
    },
    phoneApp: {
        width: "25%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },

    btn: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: "20%",
        "@media (min-width: 1600px) and (max-height: 900px)": {
            marginBottom: "7%",
        },
    },
    appBarElement: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0.6vw 1.2vw",
    },

    registration: {
        display: "flex",
        gap: "20px",
        alignItems: "centet",
        marginRight: "40px",
    },

    languageWrapper: {
        marginLeft: 10,
        padding: "5px 15px",
        background: "rgb(22,56,19)",
        borderRadius: 20,
    },

    business: {
        display: "flex",
        alignItems: "center",
        color: "white",
        border: "0.1vw solid #ffffff",
        borderRadius: "1.2vw",
        fontSize: "0.7vw",
        padding: "0.6vw",
        marginLeft: "1.2vw",
        cursor: "pointer",
    },
    businessIcon: {
        width: "1.5vw",
        marginRight: "0.6vw",
    },

    badgeNotifications: {
        "&.MuiBadge-root > span": {
            zIndex: "2",
            background:
                "linear-gradient(19.92deg, rgb(34, 13, 85) 13.3%, rgb(108, 80, 200) 86.7%)",
        },
        "&.emptyNote": {
            "&.MuiBadge-root > span": {
                display: "none",
            },
        },
    },
    inactiveMenuLink: {
        opacity: '0.5',
        cursor: 'default',
    },

    sportEventDashboard: {
        background: 'linear-gradient(180deg, rgba(92, 0, 138, 0.25) 0%, rgba(145, 34, 34, 0.25) 100%)',
        padding: "1.2vw",
        borderRadius: "0.9vw",
        color: "white",
        width: "36vw",
        height: '20vw',
        fontFamily: 'Montserrat',
    },
    eventHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1.2vw 0",
    },
    eventIcon: {
        width: "1.5vw",
        height: "1.5vw",
        backgroundColor: "white",
        borderRadius: "0.3vw",
        margin: '0 1vw',
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    eventImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover", 
        objectPosition: "center", 
    },
    eventName: {
        fontSize: "1vw",
        fontWeight: 500,
    },
    participants: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1.2vw",
    },
    participant: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    participantAvatar: {
        width: "6vw",
        height: "6vw",
        backgroundColor: "white",
        borderRadius: "50%",
        marginBottom: "0.6vw",
        padding: '1vw',
    },
    participantName: {
        fontSize: 15,
        textAlign: 'center',
    },
    participantPercentage: {
        fontSize: "1.2vw",
        fontWeight: "bold",
    },
    versusContainer: {
        width: "100%", 
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: '2vw 2vw 0 2vw',
    },
    versusLine: {
        flex: 1,
        height: '0.15vw',
        backgroundColor: "rgba(32, 33, 45, 1)",
    },
    versus: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#FFFFFF",
        padding: "0 0.5vw",
        zIndex: 1,
    },

    odds: {
        display: "flex",
        justifyContent: "center",
        marginTop: "3vw",
    },
    odd: {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        padding: "0.8vw 1.5vw",
        borderRadius: 13,
        margin: '0 2vw',
        border: '2px solid rgba(212, 216, 24, 0.6)'
    },
    oddNumber: {
        color: "rgba(255, 255, 255, 1)",
        fontFamily: 'Montserrat',
        fontWeight: "bold",
        marginRight: "2vw",
    },
    oddValue: {
        color: "rgba(255, 255, 255, 1)",
        fontFamily: 'Montserrat',
        fontWeight: "bold",
    },
    sportButton: {
        backgroundColor: "#00BA6C",
        color: "black",
        fontWeight: "bold",
        padding: "0.7vw",
        borderRadius: "0.5vw",
        width: "100%",
        border: "none",
        cursor: "pointer",
        fontSize: "1vw",
        "&:hover": {
            opacity: 0.9,
        },
    },
    participantContainer: {
        width: '6vw',
        height: '6vw',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    },
    avatarImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
});
